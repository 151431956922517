import { Button } from '../../button'
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  HiOutlineCheckCircle,
  HiOutlineMinus,
  HiOutlinePlus,
} from 'react-icons/hi'
import { twMerge } from 'tailwind-merge'

interface IAccordionItem {
  label: ReactNode
  labelClassName?: string
  contentContainerClassName?: string
  lastItem?: boolean
  isOpen?: boolean
  canBeOpened?: boolean
  onClick?: (number) => void
  accordionKey?: number
  children?: ReactNode
  requireConfirmation?: boolean
  isConfirmed?: boolean
  onConfirmation?: (index) => void
  disableConfirmation?: boolean
  className?: string
}

export interface IAccordionItemConfig {
  opened: boolean
  confirmed: boolean
}

const getInitialState = (
  numberOfChildren: number,
  initialMode: 'showFirst' | 'hideAll'
) => {
  const returnArray = new Array(numberOfChildren).fill({
    opened: false,
    confirmed: false,
  })
  if (initialMode === 'showFirst') {
    returnArray.splice(0, 1, {
      opened: true,
      confirmed: false,
    })
  }
  return returnArray
}

export const Accordion = ({
  children,
  initialMode = 'hideAll',
  className,
  requireConfirmation = false,
  initialState = null,
  onChange,
  trackInteraction = () => {},
}: {
  children: ReactNode
  initialMode?: 'showFirst' | 'hideAll'
  className?: string
  requireConfirmation?: boolean
  initialState?: IAccordionItemConfig[]
  onChange?: (state: IAccordionItemConfig[]) => void
  trackInteraction?: ({
    index,
    action,
  }: {
    index?: number
    action?: 'confirmed' | 'opened'
  }) => void
}) => {
  const [accordionState, setAccordionState] = useState<IAccordionItemConfig[]>(
    initialState || getInitialState(React.Children.count(children), initialMode)
  )

  const sectionCanBeOpened = (index) => {
    const previousSteps = accordionState.slice(0, index)
    if (requireConfirmation && previousSteps?.length) {
      return previousSteps.every((section) => section.confirmed)
    }
    return true
  }

  const onAccordionItemClick = (accordionKey: number) => {
    if (sectionCanBeOpened(accordionKey)) {
      const updatedAccordionState = accordionState.map((section, index) => {
        return {
          ...section,
          opened: index === accordionKey ? !section.opened : false,
        }
      })

      setAccordionState(updatedAccordionState)
      trackInteraction({ index: accordionKey, action: 'opened' })
    }
  }

  const accrodionItems = React.Children.toArray(children).filter(Boolean)

  const onStepConfirmation = (index) => {
    const stateCopy = Array.from(accordionState)
    stateCopy[index] = {
      confirmed: true,
      opened: false,
    }

    if (stateCopy[index + 1]) {
      stateCopy[index + 1] = {
        confirmed: false,
        opened: true,
      }
    }

    setAccordionState(stateCopy)
    trackInteraction({ index, action: 'confirmed' })
  }

  useEffect(() => {
    if (initialMode === 'showFirst') {
      const stateCopy = Array.from(accordionState)
      stateCopy.splice(0, 1, {
        opened: true,
        confirmed: false,
      })
      setAccordionState(stateCopy)
    }
  }, [initialMode])

  useEffect(() => {
    if (onChange) {
      onChange(accordionState)
    }
  }, [accordionState])

  useEffect(() => {
    if (initialState) {
      setAccordionState(initialState)
    }
  }, [initialState])

  return (
    <div className={className}>
      {accrodionItems.map((child: ReactElement, index) => {
        return React.cloneElement(child, {
          key: index,
          accordionKey: index,
          isOpen: accordionState[index]?.opened,
          canBeOpened: sectionCanBeOpened(index),
          onClick: onAccordionItemClick,
          lastItem: accrodionItems.length - 1 === index,
          requireConfirmation: requireConfirmation,
          onConfirmation: onStepConfirmation,
          isConfirmed: accordionState[index]?.confirmed,
        })
      })}
    </div>
  )
}

export const AccordionItem = ({
  label,
  lastItem = false,
  accordionKey,
  isOpen = false,
  canBeOpened = true,
  onClick,
  labelClassName,
  children,
  requireConfirmation = false,
  isConfirmed = false,
  onConfirmation,
  disableConfirmation = false,
  className = '',
  contentContainerClassName = '',
}: IAccordionItem) => {
  const [contentNode, setContentNode] = useState(null)
  const onRefChange = useCallback((node) => {
    setContentNode(node)
  }, [])

  const AccordionIcon = () =>
    isOpen ? (
      <HiOutlineMinus className="w-7 h-7 shrink-0 text-core-purple" />
    ) : (
      <HiOutlinePlus className="w-7 h-7 shrink-0 text-core-purple" />
    )

  useEffect(() => {
    if (isConfirmed && lastItem) {
      window.scrollBy(0, -contentNode?.scrollHeight)
    }
  }, [isConfirmed, lastItem])

  return (
    <>
      <div
        className={twMerge(`flex items-center py-4 border-t
          ${lastItem || isOpen ? 'border-b' : ''}
          ${children && canBeOpened ? 'cursor-pointer' : ''} ${className}`)}
        onClick={() => onClick(accordionKey)}
      >
        {requireConfirmation && (
          <HiOutlineCheckCircle
            className={`w-8 h-8 mr-5 shrink-0 ${
              isConfirmed ? 'text-starfruit-120' : 'text-acai-20'
            }`}
          />
        )}

        <h3
          className={twMerge(
            `flex items-center py-0 justify-between text-xl font-normal md:text-xl text-core-grey-darkest mr-auto ${
              labelClassName || ''
            }`
          )}
        >
          {label}
        </h3>

        {children && <AccordionIcon />}
      </div>

      {children && (
        <div
          className={`flex flex-col overflow-hidden transition-all duration-200 ease-linear ${
            isOpen ? contentContainerClassName + 'h-auto' : 'h-0'
          }`}
          ref={onRefChange}
        >
          {children}
          {requireConfirmation && (
            <Button
              variant="primary"
              colour="pitaya"
              size="s"
              corners="rounded"
              className="my-10 ml-auto"
              onClick={() => onConfirmation(accordionKey)}
              disabled={disableConfirmation}
            >
              Confirm
            </Button>
          )}
        </div>
      )}
    </>
  )
}
