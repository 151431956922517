import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { ProductTag } from '@pageFeatures/category/ProductTag'
import { IProductTag } from '@pageFeatures/category/category.types'
import { useDevice } from '@utils/responsiveness/useDevice'
import { useRouter } from 'next/router'
import { forwardRef, useEffect, useRef } from 'react'

interface ISearchResultItem {
  className: string
  onFocus: (event: React.FocusEvent<HTMLDivElement>) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
  imageURL: string
  itemName: string
  itemSlug: string
  keyword: string
  saleTag?: IProductTag
  statusTag?: IProductTag
}

const SearchResultItem = forwardRef(
  (
    {
      className,
      onFocus,
      onKeyDown,
      imageURL,
      itemName,
      itemSlug,
      keyword,
      saleTag,
      statusTag,
    }: ISearchResultItem,
    ref: any
  ) => {
    const { isMobile } = useDevice()
    const router = useRouter()
    const itemLink = useRef<HTMLAnchorElement>()

    useEffect(() => {
      const regExpToHighlightKeywords = new RegExp(
        '(' + keyword?.split(' ').join('|') + ')',
        'ig'
      )
      const linkHtml = String(itemName)?.replace(
        regExpToHighlightKeywords,
        '<b>$1</b>'
      )
      if (itemLink.current as HTMLAnchorElement) {
        itemLink.current.innerHTML = linkHtml
      }
    }, [itemName])

    return (
      <div
        ref={ref}
        className={`outline-none flex flex-row group justify-start items-center mb-5 py-[2px] ${className}`}
        onMouseEnter={() => ref?.current?.focus()}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        tabIndex={0}
      >
        <div className="flex shrink-0 min-w-[36px] sm:min-w-[28px]">
          <ContentfulNextJsImage
            width={isMobile ? 36 : 28}
            height={isMobile ? 36 : 28}
            src={imageURL}
            fit="thumb"
            quality={70}
            format="jpg"
            className="rounded-full shrink-0 min-w-[36px] sm:min-w-[28px]"
          />
        </div>

        <a
          ref={itemLink}
          className={`relative outline-none block ml-6 text-2xl sm:ml-4 sm:text-2xl font-regular sm:group-hover:text-pitaya`}
          href={itemSlug}
          tabIndex={-1}
          onClick={() => {
            router.push(itemSlug).catch(() => {})
          }}
        >
          {itemName}
        </a>

        <div className="flex items-cemter mr-0 sm:mr-auto ml-auto mb-auto sm:mb-0 sm:ml-5">
          {saleTag && (
            <ProductTag
              label={saleTag.label}
              type={saleTag.type}
              className="mr-2 border border-pitaya-20"
            />
          )}
          {statusTag && ((isMobile && !saleTag) || !isMobile) && (
            <ProductTag
              label={statusTag?.label}
              className="mr-2 border border-black-20"
            />
          )}
        </div>
      </div>
    )
  }
)

export { SearchResultItem }
