import { IProductTag, TTagType } from '@pageFeatures/category/category.types'

export function ProductTag({
  label,
  type = 'generic',
  className,
}: Partial<IProductTag> & Pick<IProductTag, 'label'> & { className?: string }) {
  const mapTagTypeToClass: Record<TTagType, string> = {
    generic: 'text-black bg-pebble',
    sale: 'text-pitaya bg-pitaya-5',
    status: 'text-acai bg-white',
  }

  return (
    <div
      className={`font-mori py-[6px] px-[12px] text-base leading-snug font-semibold rounded-[24px] whitespace-nowrap ${mapTagTypeToClass[type]} ${className}`}
      key={label}
    >
      {label}
    </div>
  )
}
